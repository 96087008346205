body {
  margin: 0;
  font-family: "Young Serif", "Times New Roman";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}



pre {
  margin: 0;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

a.peer:hover{
  text-decoration: underline;
}

.DisplayBox {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

.ContentBox {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

/* PC's CSS: width larger than 800px */
@media screen and (min-width: 800px) {
  .Content {
    width: 70%;
    margin-bottom: 2rem;
  }
}

/* Mobile CSS: width less than 800px */
@media screen and (max-width: 800px) {
  .Content {
    width: 90%;
    margin-bottom: 2rem;
  }
}

.Contact {
  background-color: rgb(234, 234, 234);
  text-align: center;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.Contact a {
  text-decoration: underline;
  font-style: italic;
  color: rgb(130, 184, 173);
}

.Contact h2 {
  margin-top: 0;
  margin-bottom: 0;
}

