.Home_TitleContainer {
  background-image: url("../img/background_strong.gif");
  width: 100%;
  background-size: cover;
  background-repeat: round;
  margin-top: 25px;
  text-align: center;
}

.Home_Title {
  padding-top: 5rem;
  padding-bottom: 5rem;
  color:white;
  font-size: 3rem;
  font-weight: 800;
  text-shadow: 0px 0px 10px rgb(255, 255, 255);
}

.Home_BlogContent .TImgTextBox_Img{
  animation-name: rotate;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.contribution {
  width: 100%;
}

@keyframes rotate{
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}