.TImgTextBox_Container {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(130, 184, 173);
    border-radius: 20px;
}

.TImgTextBox_Left {
    min-width: 100px;
    max-width: 270px;
    width: 30%;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TImgTextBox_Img {
    width: 80%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    overflow: hidden;
    border-radius: 100%;
}

.TImgTextBox_Right {
    flex: 1;
    max-width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TImgTextBox_Content {
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
}
