.TCommandLineBox_Container {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: black;
    color: rgb(0,255,0);
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
}

.TCommandLineBox_Content {
    padding: 20px;
}